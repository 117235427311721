<template>
    <div class="releazio-need-update-popup hide">
        <div class="releazio-need-update-popup_header">
            <div class="releazio-need-update-popup_header-title"></div>
            <div class="releazio-need-update-popup_header-right" v-if="isCanBeClose" @click.stop="closePopup(false)">
                <i class="icon close-svg svg-filter-grey4"></i>
            </div>
        </div>
        <div class="releazio-need-update-popup_body">
            <sloth-block :type="SLOTH_TYPE.WIN"/>
            <p class="releazio-need-update-popup_body-title">{{ $t("releazio-popup.title") }}</p>
            <p class="releazio-need-update-popup_body-description">{{ updateText }}</p>
            <f7-button class="theme-type" @click.stop="openDeeplink">{{ $t("releazio-popup.button") }}</f7-button>
            <p v-if="serviceReleazio!.hasVersionPost()" class="releazio-need-update-popup_body-posts-link" @click.stop="toReleazioPostsPage">
                {{ $t("releazio-popup.posts-link") }}</p>
        </div>
    </div>
</template>

<script setup lang="ts">
import {SLOTH_TYPE} from "@/entities/enums/SlothType";
import SlothBlock from "@components/messages/SlothBlock.vue";
// @ts-ignore
import AppController from "@target/components/App/ts/AppController";
import ServiceReleazio from "@/services/v2/service-releazio/ServiceReleazio";
import RouterService from "@/services/RouterService";
import {computed} from "@vue/reactivity";

const serviceReleazio: ServiceReleazio | null = AppController.of().service<ServiceReleazio>("releazio");
const updateText = computed(() => {
    return serviceReleazio?.activeConfig.value?.updateMessage;
});
const isCanBeClose = computed(() => {
   return serviceReleazio?.activeConfig.value?.updateType !== 3;
});

function openDeeplink() {
    if (serviceReleazio) {
        serviceReleazio.openDeepLink();
    }
}

function closePopup(force: boolean = false) {
    if (serviceReleazio) {
        serviceReleazio.closeNeedUpdatePopup(force);
    }
}

function toReleazioPostsPage() {
    closePopup(true);
    const serviceRouter = AppController.of().service<RouterService>("router");
    if (serviceRouter) {
        serviceRouter.toReleazioPostPage();
        if (serviceReleazio?.activeConfig.value?.updateType === 3) {
            serviceReleazio.isNeedUpdatePopupWillReopen = true;
        }
    }
}
</script>

<style lang="scss">
.releazio-need-update-popup {
    position: absolute;
    width: 310px;
    z-index: 13501;
    background: var(--white1);
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) scale(1);
    will-change: transform, opacity;
    transition-property: transform, opacity;
    transition-duration: 400ms;
    overflow: hidden;
    border-radius: 16px;
    padding: 16px 16px 28px;
    display: block;

    &.hide {
        transform: translate3d(-50%, -50%, 0) scale(1.185);
        opacity: 0;
        display: none;
    }

    &_header {
        display: flex;
        justify-content: space-between;

        &-title {
            flex: 1;
        }

        &-right {
            cursor: pointer;
            flex: 0;

            .close-svg {
                width: 18px;
                height: 18px;
            }
        }
    }

    &_body {
        display: flex;
        flex-direction: column;
        padding: 0 8px;

        .info-block {
            .icon {
                width: 88px;
                height: 88px;
            }
        }

        &-title {
            margin-top: 12px;
            @include text(20px, 600, var(--brown1), 24px);
            text-align: center;

        }

        &-description {
            margin-top: 8px;
            @include text(14px, 400, var(--brown1), 16.8px);
            text-align: center;
        }

        .button {
            margin-top: 24px;
        }

        &-posts-link {
            margin-top: 20px;
            @include text(16px, 500, var(--blue1), 19.2px);
            text-align: center;
            cursor: pointer;
        }
    }
}

.releazio-need-update-popup_backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    will-change: transform, opacity;
    transition-property: transform, opacity;
    transition-duration: 400ms;
    z-index: 11000;
    opacity: 0;
}
</style>