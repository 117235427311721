<template>
    <f7-views class="safe-areas main-views" ref="main-views">
        <f7-fab-buttons v-if="socketIsConnecting" position="right-top" class="socket-state">
            <div class="loader-2 center"><span></span></div>
        </f7-fab-buttons>
        <f7-view main url="/payments" :preload-previous-page="false" :ios-swipe-back="false"></f7-view>
        <f7-view name="higher" url="/higher" :preload-previous-page="false" :ios-swipe-back="false"></f7-view>

        <hunting-agents-toast/>
    </f7-views>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import AppController from "../App/ts/AppController";
import HuntingAgentsToast from "@components/toasts/HuntingAgentsToast.vue";
import ReleazioNeedUpdatePopup from "@components/popup/popup-releazio/ReleazioNeedUpdatePopup.vue";

export default defineComponent({
    components: {ReleazioNeedUpdatePopup, HuntingAgentsToast},
    setup() {
        const vc = AppController.getInstance();

        return {
            socketIsConnecting: vc.socketIsConnecting
        }
    }
});
</script>

<style lang="scss">
.right-top {
    top: calc(16px + var(--f7-safe-area-top));
}
</style>

