<template>
    <f7-page
        class="payments-view"
        name="main"
        :page-content="false"
        @page:beforein="pageBeforeInEvent"
        @page:afterin="pageAfterInEvent"
        @page:beforeout="beforeOut"
    >
        <Navbar
            :viewNavbarBalance="viewNavbarBalance"
            :viewNavbarLogo="viewNavbarLogo"
            @openSheetModalForNewPayment="openSheetModalForNewPayment"
        />
        <f7-page-content ptr @ptr:refresh="eventPtrRefresh" infinite @infinite="eventInfinite">
            <Transition name="fade" mode="out-in">
                <BalanceBlock
                    v-if="account"
                    :account="account"
                    @openSheetModalForWithdrawal="openSheetModalForWithdrawal"
                    @openSheetModalForNewPayment="openSheetModalForNewPayment"
                    @infoClick="openBalanceHintSheet"
                />
                <BalanceBlockSkeleton v-else/>
            </Transition>
            <Transition name="fade" mode="out-in">
                <template v-if="payments">
                    <Transition name="fade" mode="out-in">
                        <template v-if="payments.length > 0">
                            <my-payments @openSheetModalForNewPayment="openSheetModalForNewPayment"/>
                        </template>

                        <template v-else>
                            <SlothBlock
                                :title="$t('g.payments.noPayments.specific.title')"
                                :subtitle="$t('g.payments.noPayments.specific.text')"
                                :type="SLOTH_TYPE.WIN"
                            />
                        </template>
                    </Transition>
                </template>
                <MyPaymentsSkeleton v-else/>
            </Transition>
        </f7-page-content>
        <no-agents-sheet/>
        <ban-sheet
            v-if="account?.settings.ban"
            ref="ban-sheet"
            :account="account"
        />
        <balance-hint-sheet v-if="isBalanceHintSheet" @closed="isBalanceHintSheet = false"/>
    </f7-page>
</template>

<script lang="ts">
import { useI18n } from 'vue-i18n'
import PaymentsViewController from "./ts/PaymentsViewController";
import { computed, defineAsyncComponent, ref } from "vue";
// @ts-ignore
import store from "@target/core/store/store";
import PaymentSystem from "@/entities/PaymentSystem";
import Message, {MessageType} from "@/entities/Message";
import {f7, useStore} from "framework7-vue";
import {getDevice} from "framework7/shared/get-device";
import BalanceBlock from '@components/atomic/balance/BalanceBlock.vue';
import Navbar from "@/targets/main/views/payments/components/Navbar.vue";
import {SLOTH_TYPE} from "@/entities/enums/SlothType";
import SlothBlock from "@/components/messages/SlothBlock.vue";
import AppController from "@/targets/main/components/App/ts/AppController";
import BalanceBlockSkeleton from "@/targets/main/views/payments/components/BalanceBlockSkeleton.vue";
import MyPaymentsSkeleton from "@/targets/main/views/payments/components/MyPaymentsSkeleton.vue";
import ErrorsService from "@/services/errors-service/ErrorsService";
import GlobalRequisitesController from "@/views/requisites-group/GlobalRequisitesController";
import RouterService from "@/services/RouterService";
import LogService from "@/services/log-service/LogService";
import ServiceAccount from "@/services/v2/data/service-account/ServiceAccount";
import ServiceRates from "@/services/v2/data/service-rates/ServiceRates";

export default {
    computed: {
        SLOTH_TYPE() {
            return SLOTH_TYPE
        }
    },
    components: {
        MyPaymentsSkeleton,
        BalanceBlockSkeleton,
        SlothBlock,
        Navbar,
        BalanceBlock,
        'no-agents-sheet': defineAsyncComponent(() => import('@/components/sheets/NoAgentsSheet.vue')),
        'ban-sheet': defineAsyncComponent(() => import('@/components/sheets/BanSheet.vue')),
        'balance-hint-sheet': defineAsyncComponent(() => import('@components/sheets/BalanceHintSheet.vue')),
        'my-payments': defineAsyncComponent(() => import('./components/MyPayments.vue')),
        'message-error': defineAsyncComponent(() => import('@/components/messages/error/MessageError.vue')),
    },
    setup() {
        const routerService: RouterService = AppController.getInstance().routerService;
        const {t} = useI18n({useScope: 'global'});
        const vc = PaymentsViewController.getInstance();
        const isBalanceHintSheet = ref(false);
        const account = ServiceAccount.of().account;
        //TODO not used
        const bromoneyUserData = useStore('client');
        const paymentSystems = computed((): PaymentSystem[] => store.getters.paymentSystems.value);
        const payment = useStore('payment');
        const payments = useStore('myPayments');
        //TODO not used
        const noPaymentSystemsMessage: Message = {
            title: t("g.payments.pause.title"),
            text: t("g.payments.pause.text"),
            type: MessageType.error
        };
        const allowInfinity = false;
        //TODO по сути не нужна, так как была перенесена в BlockBalance
        const nextbtnLoading = ref(false);
        //TODO по сути не нужна, так как была перенесена в BlockBalance
        const btnWithdrawalLoading = ref(false);
        //TODO not used
        const {init} = vc;
        const requisitePs = GlobalRequisitesController.getInstance();
        //TODO not used
        const popupForNewPayment = null;
        const viewNavbarBalance = ref(false);
        const viewNavbarLogo = ref(false);
        const appIsReady = AppController.getInstance().isReady;
        const totalOperations = -1;

        return {
            routerService,
            bromoneyUserData,
            totalOperations,
            isBalanceHintSheet,
            appIsReady,
            viewNavbarLogo,
            viewNavbarBalance,
            requisitePs,
            btnWithdrawalLoading,
            t,
            init,
            nextbtnLoading,
            payments,
            popupForNewPayment,
            payment,
            noPaymentSystemsMessage,
            paymentSystems,
            account,
            vc,
            allowInfinity,
        }
    },
    watch: {
        'payments'() {
            setTimeout(() => {
                this.updateInfinityScroll();
            }, 700)
        },
        async 'appIsReady'(value: boolean) {
            try {
                if (value) {
                    await this.vc.updateAccountData();
                    await this.fetchOperations({refresh: true});
                }
            } catch (e) {
                ErrorsService.of().handle(e);
            }
        }
    },
    mounted() {
        this.updateInfinityScroll();
    },
    methods: {
        async fetchOperations(config: any) {
            try {
                const { meta } = await store.dispatch('fetchMyPayments', config);
                this.totalOperations = meta.total;
            } catch (e) {
                ErrorsService.of().handle(e);
            }
        },
        openBalanceHintSheet() {
            this.isBalanceHintSheet = true;
        },
        //TODO not used
        TO_SETTINGS() {
            f7.views.current.router.navigate('/settings');
        },
        //TODO not used
        CLOSE() {
            switch (this.account?.settings.homeUrlType) {
                case 'webview':
                    window.open(this.account.settings.homeUrl, '_self');
                    break;
                case 'deeplink':
                    try {
                        if (getDevice().android) {
                            window.webInterface.onMessage(JSON.stringify({
                                "deeplink": this.account.settings.homeUrl
                            }));
                        }
                        if (getDevice().ios) {
                            window.webkit.messageHandlers.iosListener.postMessage(JSON.stringify({
                                "deeplink": this.account.settings.homeUrl
                            }));
                        }
                    } catch (e) {
                        // console.log(e);
                    }
                    break;
                default:
                    break;
            }
        },
        async pageBeforeInEvent() {
            if (this.appIsReady) {
                await this.vc.updateAccountData();
                await this.fetchOperations({refresh: true});
            }
        },
        pageAfterInEvent() {
            AppController.getInstance().setPaymentRequestData(null);
            // this.showAlertWithWithdrawalWarning();
        },
        //TODO not used
        showAlertWithWithdrawalWarning() {
            if (!localStorage.getItem("is_showed_withdrawal_delay_alert")) {
                f7.dialog.alert(this.t("alerts.withdrawal-warning-delays.text"), this.t("alerts.withdrawal-warning-delays.title"));
                localStorage.setItem("is_showed_withdrawal_delay_alert", "true")
            }
        },
        //TODO used in template, but inside commented
        beforeOut() {
            // this.vc.unSyncSocket();
        },
        updateInfinityScroll() {
            if (!this.payments || this.payments.length >= this.totalOperations) {
                (document.querySelector('.infinite-scroll-preloader') as HTMLDivElement).style.display = 'none';
                this.allowInfinity = false;
            } else {
                (document.querySelector('.infinite-scroll-preloader') as HTMLDivElement).style.display = 'block';
                this.allowInfinity = true;
            }
        },
        async openSheetModalForWithdrawal(callback?: Function) {
            try {
                // this.btnWithdrawalLoading = true;
                await store.dispatch('setPaymentWithdrawal', undefined);
                GlobalRequisitesController.getInstance().activeRequisiteAmountRange.value = null;
                // await this.requisitePs.fetchAllRequisites();
                await ServiceRates.of().fetchRates();
                // await this.requisitePs.checkActiveRequisite();
                await this.requisitePs.fetchAddressTypes();

                if (this.requisitePs.activeRequisiteAmountRange.value) {
                    const {min} = this.requisitePs.activeRequisiteAmountRange.value;

                    if (min <= 0) {
                        this.openPopupNoAgents();
                        return;
                    }
                }

                f7.views.main.router.navigate('/popup/withdrawal')
            } catch (e: any) {
                LogService.of().log(e);
                ErrorsService.of().handle(e);
            } finally {
                if (typeof callback === "function") callback();
                // this.btnWithdrawalLoading = false;
            }
        },
        openPopupNoAgents() {
            const el: HTMLDivElement = document.querySelector('.no-agents-sheet')!;
            const sheet = f7.sheet.create({
                el,
            });
            sheet.open();
        },
        async openSheetModalForNewPayment(callback?: Function) {
            try {
                const self = this;

                // const systemInfo = await store.dispatch("checkSystemReadyToPay", null);
                // if (!systemInfo.available) {

                // }

                // store.dispatch('fetchPaymentSystems', null);
                // await store.dispatch('fetchRates'); //TODO можно удалить, его больше нет в Store
                // await store.dispatch('fetchCurrencies', null);
                //
                // if (store.getters.paymentSystems.value.length === 0) {
                //     this.openPopupNoAgents();
                //     return;
                // }

                // await store.dispatch('setPayment', undefined);

                if (typeof callback !== "function") this.nextbtnLoading = true;

                // if (systemInfo.oldPaymentsCount > 0) {
                //     f7.dialog.create({
                //         title: this.t("g.payments.cancelPayment.title"),
                //         text: this.t("g.payments.cancelPayment.text"),
                //         buttons: [
                //             {
                //                 text: this.t("g.payments.cancelPayment.no"),
                //             },
                //             {
                //                 text: this.t("g.payments.cancelPayment.yes"),
                //                 onClick: async () => {
                //                     f7.preloader.show();
                //                     await store.dispatch('cancelPayments', null);
                //                     await self.vc.updateAccountData();
                //                     f7.preloader.hide();
                //                 }
                //             }
                //         ]
                //     }).open();
                //     return;
                // }
                // f7.views.main.router.navigate('/popup/main');
                await this.routerService.toCreatePaymentPopup();
            } catch (e) {
                f7.dialog.alert(this.t("g.payments.alert.first"), this.t("g.payments.alert.second"), () => {
                });
            } finally {
                if (typeof callback === "function") callback();
                else this.nextbtnLoading = false;
            }
        },
        async eventInfinite() {
            if (this.allowInfinity) {
                this.allowInfinity = false;
                await this.fetchOperations({refresh: false});
            }
        },
        async eventPtrRefresh(done: any) {
            try {
                await this.vc.updateMyAccount();
                await this.vc.updateMyPayments();
                // await store.dispatch('fetchPaymentSystems', null);
                // await store.dispatch('fetchRates'); //TODO можно удалить, его больше нет в Store
                // await store.dispatch('fetchCurrencies', null);
                this.updateInfinityScroll();
                this.allowInfinity = true;
            } catch (e: any) {
                ErrorsService.of().handle(e);
            } finally {
                done();
            }
        }
    },
    unmounted() {
        this.vc.destructor();
    }
};
</script>

<style src="./index.scss" lang="scss"></style>
