<template>
    <f7-app v-bind="vc.config">
        <Transition name="fade" mode="out-in">
            <MainViews v-if="vc.isAuth.value"/>
            <AuthViews v-else/>
        </Transition>

        <releazio-need-update-popup/>
    </f7-app>
</template>

<script lang="ts" setup>
// @ts-ignore
import {f7, f7ready} from 'framework7-vue';
import AppController from "./ts/AppController";
import AuthViews from "../views/LoginView.vue";
import MainViews from "../views/MainViews.vue";
import {getDevice} from "framework7/shared/get-device";
import cordovaApp from '../../core/cordova-app.js';
import {onMounted} from "vue";
import ServiceReleazio from "@/services/v2/service-releazio/ServiceReleazio";
import ReleazioNeedUpdatePopup from "@components/popup/popup-releazio/ReleazioNeedUpdatePopup.vue";
import LangService from "@/services/LangService";

const vc = AppController.of();
vc.beforeInitStep().init();

onMounted(async () => {
    f7ready(async () => {
        if (getDevice().cordova) {
            cordovaApp.init(f7);
        }
        runCheckReleazioVersion();
    });
});

async function runCheckReleazioVersion() {
    const serviceRealizio = AppController.of().service<ServiceReleazio>("releazio");
    if (serviceRealizio) {
        await serviceRealizio.fetchVersionConfig(LangService.getInstance().get());
        serviceRealizio.updateViewPost();
        serviceRealizio.checkCurrentVersionOld();

        if (serviceRealizio.isNeedUpdate.value) {
            serviceRealizio.openNeedUpdatePopup();
        }
    }
}

</script>
