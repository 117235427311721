import ServiceDI from "@/services/v2/service-DI/ServiceDI";

export default class BaseAppController {
    protected _serviceDI: ServiceDI | null = null;

    constructor() {

    }

    public async init() {
        this._serviceDI = this.createServiceDI();
    }

    protected createServiceDI() {
        return ServiceDI.builder()
            .addPack([])
            .build();
    }

    public service<T>(name: string): T | null {
        if (!this._serviceDI) throw new Error("Service DI is not initialized");
        return this._serviceDI.get<T>(name);
    }

    public setToken(token: string | null) {
        if (token) {
            localStorage.setItem("token", token);
        } else {
            localStorage.removeItem("token");
            localStorage.removeItem("ltoken");
            localStorage.removeItem("lrefreshToken");
            localStorage.removeItem("csp");
        }
    }
}