import StatusBarService from "@/services/StatusBarService";

var cordovaApp = {
    isCanShrinkViewManually: true,
    f7: null,
    /*
    This method hides splashscreen after 2 seconds
    */
    handleSplashscreen: function () {
        var f7 = cordovaApp.f7;
        if (!window.navigator.splashscreen) return;
        setTimeout(() => {
            window.navigator.splashscreen.hide();
        }, 2000);
    },
    /*
    This method prevents back button tap to exit from main on android.
    In case there is an opened modal it will close that modal instead.
    In case there is a current view with navigation history, it will go back instead.
    */
    handleAndroidBackButton: function () {
        var f7 = cordovaApp.f7;
        const $ = f7.$;

        document.addEventListener(
            'backbutton',
            function (e) {
                if ($('.actions-modal.modal-in').length) {
                    f7.actions.close('.actions-modal.modal-in');
                    e.preventDefault();
                    return false;
                }
                if ($('.dialog.modal-in').length) {
                    f7.dialog.close('.dialog.modal-in');
                    e.preventDefault();
                    return false;
                }
                if ($('.sheet-modal.modal-in').length) {
                    f7.sheet.close('.sheet-modal.modal-in');
                    e.preventDefault();
                    return false;
                }
                if ($('.popover.modal-in').length) {
                    f7.popover.close('.popover.modal-in');
                    e.preventDefault();
                    return false;
                }
                if ($('.popup.modal-in').length) {
                    if ($('.popup.modal-in>.view').length) {
                        const currentView = f7.views.get('.popup.modal-in>.view');
                        if (currentView && currentView.router && currentView.router.history.length > 1) {
                            currentView.router.back();
                            e.preventDefault();
                            return false;
                        }
                    }
                    f7.popup.close('.popup.modal-in');
                    e.preventDefault();
                    return false;
                }
                if ($('.login-screen.modal-in').length) {
                    f7.loginScreen.close('.login-screen.modal-in');
                    e.preventDefault();
                    return false;
                }

                if ($('.page-current .searchbar-enabled').length) {
                    f7.searchbar.disable('.page-current .searchbar-enabled');
                    e.preventDefault();
                    return false;
                }

                if ($('.page-current .card-expandable.card-opened').length) {
                    f7.card.close('.page-current .card-expandable.card-opened');
                    e.preventDefault();
                    return false;
                }

                const currentView = f7.views.current;
                if (currentView && currentView.router && currentView.router.history.length > 1) {
                    currentView.router.back();
                    e.preventDefault();
                    return false;
                }

                if ($('.panel.panel-in').length) {
                    f7.panel.close('.panel.panel-in');
                    e.preventDefault();
                    return false;
                }
            },
            false,
        );
    },
    /*
    This method does the following:
      - provides cross-platform view "shrinking" on keyboard open/close
      - hides keyboard accessory bar for all inputs except where it required
    */
    handleKeyboard: function () {
        var f7 = cordovaApp.f7;
        if (!window.Keyboard || !window.Keyboard.shrinkView) return;
        var $ = f7.$;

        window.Keyboard.shrinkView(false);
        window.Keyboard.disableScrollingInShrinkView(true);
        window.Keyboard.hideFormAccessoryBar(false);
        window.addEventListener('keyboardWillShow', () => {
            f7.input.scrollIntoView(document.activeElement, 0, true, true);
        });
        window.addEventListener('keyboardDidShow', () => {
            console.log("keyboardDidShow")
            f7.input.scrollIntoView(document.activeElement, 0, true, true);
        });
        window.addEventListener('keyboardDidHide', () => {
            if (document.activeElement && $(document.activeElement).parents('.messagebar').length) {
                return;
            }
            window.Keyboard.hideFormAccessoryBar(false);
            this.isCanShrinkViewManually = true;
        });
        window.addEventListener('keyboardHeightWillChange', (event) => {
            const keyboardHeight = event.keyboardHeight;
            switch (f7.view.current.router.currentRoute.path) {
                case '/chat-withdrawal':
                case '/payment/create/config':
                case '/popup/withdrawal/create':
                case '/chat':
                    if (keyboardHeight > 0) {
                        document.body.style.height = `calc(100% - ${keyboardHeight}px)`;
                        $('html').addClass('device-with-keyboard');
                    } else {
                        document.body.style.height = '';
                        $('html').removeClass('device-with-keyboard');
                    }
                case '/requisite':
                    if (keyboardHeight > 0) {
                        document.body.style.height = `calc(100% - ${keyboardHeight}px)`;
                    } else {
                        document.body.style.height = '';
                    }

            }
        });
        $(document).on(
            'touchstart',
            'input, textarea, select',
            function (e) {
                var nodeName = e.target.nodeName.toLowerCase();
                var type = e.target.type;
                var showForTypes = ['datetime-local', 'time', 'date', 'datetime'];
                if (nodeName === 'select' || showForTypes.indexOf(type) >= 0) {
                    window.Keyboard.hideFormAccessoryBar(false);
                } else {
                    window.Keyboard.hideFormAccessoryBar(true);
                }
            },
            true,
        );
    },
    init: function (f7) {
        // Save f7 instance
        cordovaApp.f7 = f7;
        // StatusBar.styleDefault();

        document.addEventListener('deviceready', () => {
            // StatusBarService.toDarkContent();
            // StatusBar.overlaysWebView(true);
            StatusBar.styleDefault();

            // Handle Android back button
            cordovaApp.handleAndroidBackButton();

            // Handle Splash Screen
            cordovaApp.handleSplashscreen();

            // Handle Keyboard
            cordovaApp.handleKeyboard();
        });
    },
};

export default cordovaApp;
